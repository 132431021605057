import Modal from '../../components/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { db } from '../../db';
import Swal from 'sweetalert2';

function AddTeacherCmp({ show, hide, afterLeave }) {
  const { t } = useTranslation()
  const [inputs, setItem] = useState({
    name: '',
    subject: '',
    email: '',
    tel: '',
    status: '',
  });
  const [errorsList, setErrors] = useState([]);
  function resetInputs() {
    setItem({
      name: '',
      subject: '',
      email: '',
      tel: '',
    });
    setErrors([]);
  }

  const handleInput = (e) => {
    e.persist();
    setItem({ ...inputs, [e.target.name]: e.target.value });
  };
  const submitItem = async (e) => {
    e.preventDefault();
    setErrors({});
    const data = {
      name: inputs.name.trim(),
      subject: inputs.subject,
      email: inputs.email,
      tel: inputs.tel,
      status: 1,
    };
    if (!data.name)
      setErrors((errors) => ({
        ...errors,
        name: t('name required'),
      }));

    if (data.name)
      try {
        // Add the new friend!
        await db.teachers.add(data);
        resetInputs();
        Swal.fire(t("success"), t("teacher added successfully"), 'success');
      } catch (error) {
        error.inner.code == 0
          ? setErrors((errors) => ({ ...errors, name: t("name already exists") }))
          : Swal.fire('Erreur', error.inner.message, 'error');
      }
  };
  return (
    <Modal
      show={show}
      hide={hide}
      dialogClassName="w-full sm:max-w-2xl h-fit my-auto pb-5 rounded-xl"
      afterLeave={afterLeave}
    >
      <div className="border-b mb-5 p-4 rounded-t-xl bg-gray-100 font-bold text-xl">
      {t('add teacher')}
      </div>
      <form onSubmit={submitItem} className="flex flex-col gap-4 px-6">
        <div className="flex flex-col">
          <label>{t('name')}</label>
          <input
            type="text"
            name="name"
            onChange={handleInput}
            value={inputs.name}
            className="p-2 border outline-none ring-gray-200 rounded focus:ring focus:ring-blue-400 transition duration-200"
          />
          <small className="text-red-500">{errorsList.name}</small>
        </div>
        <div className="flex flex-col">
          <label>{t('subject')}</label>
          <input
            type="text"
            name="subject"
            onChange={handleInput}
            value={inputs.subject}
            className="p-2 border outline-none ring-gray-200 rounded focus:ring focus:ring-blue-400 transition duration-200"
          />
          <small className="text-red-500">{errorsList.subject}</small>
        </div>
        <div className="flex flex-col">
          <label>{t('email')}</label>
          <input
            type="email"
            name="email"
            onChange={handleInput}
            value={inputs.email}
            className="p-2 border outline-none ring-gray-200 rounded focus:ring focus:ring-blue-400 transition duration-200"
          />
          <small className="text-danger">{errorsList.email}</small>
        </div>
        <div className="flex flex-col">
          <label>{t('phone')}</label>
          <input
            type="text"
            name="tel"
            onChange={handleInput}
            value={inputs.tel}
            className="p-2 border outline-none ring-gray-200 rounded focus:ring focus:ring-blue-400 transition duration-200"
          />
          <small className="text-red-500">{errorsList.tel}</small>
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-blue-500 text-white rounded-full w-[50%] p-2 font-bold"
          >
            {t("submit")}
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default AddTeacherCmp;
