import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbare.css';

import { arrowBack } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { LANGUAGES } from '../constants';
import { useTranslation } from 'react-i18next';

export default function Navbare() {
  const navigate = useNavigate();
  const toggleSidebar = (e) => {
    e.persist();
    document.body.classList.toggle('sidenav-toggled');
    localStorage.setItem(
      'sidebar-toggle',
      document.body.classList.contains('sidenav-toggled')
    );
  };

  const { i18n, t } = useTranslation()

  const onChangeLang = (e) => {
      const lang_code = e.target.value;
      i18n.changeLanguage(lang_code);
  }

  return (
    <nav className="flex items-center justify-between bg-white z-50 p-2 px-4 shadow-xl fixed top-0 w-full">
      <div className="flex gap-12">
        <button
          type="button"
          className="flex items-center"
          onClick={() => {
            navigate(-1);
          }}
        >
          <IonIcon className="text-2xl flex-start" icon={arrowBack} />
        </button>
        <Link className="nav-item px-1" to="/home">
          <img src="/logo-andalus.png" width="30" height="30" alt="" />
        </Link>
      </div>
      <img scr="assets/icon.png" alt="" />
      <select defaultValue={i18n.language} onChange={onChangeLang}>
        {LANGUAGES.map(({ code, label }) => (
          <option key={code} value={code}>
            {label}
          </option>
        ))}
      </select>
    </nav>
  );
}
