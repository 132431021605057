import { IonIcon } from '@ionic/react';
import { checkmarkCircle } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Success() {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center items-center pt-6">
      <div className="text-center">
        <div className="item-center">
          <IonIcon
            className="text-8xl flex-start text-green-500"
            icon={checkmarkCircle}
          />
        </div>
        <div className="font-bold text-2xl m-3">{t("success")}</div>
        <div className="m-3">{t("session added successfully")}</div>
      </div>
    </div>
  );
}
