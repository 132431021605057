import { useLiveQuery } from 'dexie-react-hooks';
import React, { useEffect, useState } from 'react';
import { db } from '../../../db';
import Swal from 'sweetalert2';
import LoadingCmp from '../../LoadingCmp';
import { useTranslation } from 'react-i18next';

export default function EditSessionByTeacherCmp(props) {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [subjectsList, setSubjects] = useState([]);
  const [errorsList, setErrors] = useState({});
  const subjects = useLiveQuery(() => db.subjects.toArray());
  useEffect(() => {
    if (subjects) {
      setSubjects(subjects);
    } else {
      setSubjects([]);
    }
  }, [subjects]);

  const [teacher, setTeacher] = useState([]);
  useEffect(() => {
    if (props.toedit) {
      db.teachers.get(Number.parseInt(props.toedit.teacher_id)).then((res) => {
        if (res) {
          setTeacher(res);
          setLoading(false);
        }
      });
    }
  }, [props.toedit]);

  const [classesList, setClasses] = useState([]);
  const classes = useLiveQuery(() => db.classes.toArray());
  useEffect(() => {
    if (classes) {
      setClasses(classes);
    } else {
      setClasses([]);
    }
  }, [classes]);

  const [classroomsList, setClassrooms] = useState([]);
  const classrooms = useLiveQuery(() =>
    db.classrooms.where({ status: 1 }).toArray()
  );
  useEffect(() => {
    if (classrooms) {
      setClassrooms(classrooms);
    } else {
      setClassrooms([]);
    }
  }, [classrooms]);

  const [inputs, setInputs] = useState([]);
  useEffect(() => {
    setInputs({
      subject_id: '',
      class_id: '',
      teacher_id: props.toedit.teacher_id,
      classroom_id: '',
      weekday: props.toedit.weekday,
      start_time: props.toedit.start_time,
      end_time: '',
      errorsList: [],
    });
  }, [props.toedit]);

  const handleInput = (e) => {
    e.persist();
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    db.sessions.get(Number.parseInt(props.toedit.id)).then((res) => {
      if (res) {
        setInputs(res);
        setLoading(false);
      }
    });
  }, [props.toedit]);

  const updateSession = async (e) => {
    e.preventDefault();
    setErrors({});
    if (!inputs.end_time) {
      setErrors((errors) => ({
        ...errors,
        end_time: t("end time required"),
      }));
    }
    if (!inputs.subject_id) {
      setErrors((errors) => ({
        ...errors,
        subject_id: t("subject required"),
      }));
    }
    if (!inputs.class_id && inputs.subject_id != 1) {
      setErrors((errors) => ({
        ...errors,
        class_id: t("class required"),
      }));
    }
    if (!inputs.classroom_id) {
      setErrors((errors) => ({
        ...errors,
        classroom_id: t("classroom required"),
      }));
    }
    if (
      inputs.end_time &&
      inputs.subject_id &&
      inputs.teacher_id &&
      inputs.classroom_id
    ) {
      let data = {};
      if (inputs.subject_id == 1) {
        data = {
          subject_id: Number.parseInt(inputs.subject_id),
          teacher_id: Number.parseInt(inputs.teacher_id),
          classroom_id: Number.parseInt(inputs.classroom_id),
          weekday: inputs.weekday,
          start_time: inputs.start_time,
          end_time: inputs.end_time,
        };
      } else {
        data = {
          subject_id: Number.parseInt(inputs.subject_id),
          class_id: Number.parseInt(inputs.class_id),
          teacher_id: Number.parseInt(inputs.teacher_id),
          classroom_id: Number.parseInt(inputs.classroom_id),
          weekday: inputs.weekday,
          start_time: inputs.start_time,
          end_time: inputs.end_time,
        };
      }
      const teacher = await db.teachers.get(data.teacher_id);
      const classroom = await db.classrooms.get(data.classroom_id);
      let classe = {};
      if (data.class_id) classe = await db.classes.get(data.class_id);
      else classe = {};
      let teacherNotFree = false;
      let classNotFree = false;
      let classroomNotFree = false;
      db.sessions
        .where('weekday')
        .equals(inputs.weekday)
        .toArray()
        .then((res) => {
          res.map((session) => {
            if (session.id != inputs.id)
              if (
                (data.start_time >= session.start_time &&
                  data.start_time < session.end_time) ||
                (data.end_time > session.start_time &&
                  data.end_time <= session.end_time) ||
                (data.start_time <= session.start_time &&
                  data.end_time >= session.end_time)
              ) {
                if (session.class_id == data.class_id) {
                  classNotFree = true;
                } else if (session.teacher_id == data.teacher_id) {
                  teacherNotFree = true;
                } else if (session.classroom_id == data.classroom_id) {
                  classroomNotFree = true;
                }
              }
          });
        })
        .then(async () => {
          if (classNotFree && data.subject_id != 1) {
            Swal.fire(
              t("error"),
              `${t("class")} ${classe.name} ${t("not available at this time")}`,
              'error'
            );
          } else if (teacherNotFree) {
            Swal.fire(
              t("error"),
              `${t("teacher")} ${teacher.name} ${t("not available at this time")}`,
              'error'
            );
          } else if (classroomNotFree && data.classroom_id != 1) {
            Swal.fire(
              t("error"),
              `${t("classroom")} ${classroom.name} ${t("not available at this time")}`,
              'error'
            );
          } else {
            try {
              await db.sessions.put(data, [props.toedit.id]);
              Swal.fire(t("success"), t("updated successfully"), 'success');
            } catch (error) {
              Swal.fire(t('error'), error.inner.message, 'error');
            }
          }
        });
    }
  };
  if (loading) {
    return <LoadingCmp />;
  }
  return (
    <form onSubmit={updateSession} className="flex flex-col gap-4 px-8 pt-6">
      <div className="">
        <label>{t("teacher")}</label>
        <div className="flex flex-col">
          <select
            name="class_id"
            value={inputs.teacher_id}
            className="border p-2 rounded"
            disabled
          >
            <option>{t("select teacher")}</option>
            <option value={inputs.teacher_id}>{teacher.name}</option>
          </select>
        </div>
      </div>

      <div className="">
        <label>{t("weekday")}</label>
        <div className="flex flex-col">
          <select
            name="weekday"
            value={inputs.weekday}
            className="border p-2 rounded"
            disabled
          >
            <option value={inputs.weekday}>{t(inputs.weekday)}</option>
          </select>
        </div>
      </div>
      <div className="">
        <label>{t("start time")}</label>
        <div className="flex flex-col">
          <select
            name="start_time"
            value={inputs.start_time}
            className="border p-2 rounded"
            disabled
          >
            <option value={inputs.start_time}>{inputs.start_time}</option>
          </select>
        </div>
      </div>
      <div className="">
        <label>{t("end time")}</label>
        <div className="flex flex-col">
          <select
            name="end_time"
            onChange={handleInput}
            value={inputs.end_time}
            className="border p-2 rounded"
          >
            <option value="">{t("select end time")}</option>
            {inputs.start_time < '09:00' &&
            '09' - inputs.start_time?.substr(0, 2) < 5 ? (
              <option value="09:00">09:00</option>
            ) : (
              ''
            )}
            {inputs.start_time < '10:00' &&
            '10' - inputs.start_time?.substr(0, 2) < 5 ? (
              <option value="10:00">10:00</option>
            ) : (
              ''
            )}
            {inputs.start_time < '11:00' &&
            '11' - inputs.start_time?.substr(0, 2) < 5 ? (
              <option value="11:00">11:00</option>
            ) : (
              ''
            )}
            {inputs.start_time < '12:00' &&
            '12' - inputs.start_time?.substr(0, 2) < 5 ? (
              <option value="12:00">12:00</option>
            ) : (
              ''
            )}
            {inputs.start_time < '13:00' &&
            '13' - inputs.start_time?.substr(0, 2) < 5 ? (
              <option value="13:00">13:00</option>
            ) : (
              ''
            )}
            {inputs.start_time < '14:00' &&
            '14' - inputs.start_time?.substr(0, 2) < 5 ? (
              <option value="14:00">14:00</option>
            ) : (
              ''
            )}
            {inputs.start_time < '15:00' &&
            '15' - inputs.start_time?.substr(0, 2) < 5 ? (
              <option value="15:00">15:00</option>
            ) : (
              ''
            )}
            {inputs.start_time < '16:00' &&
            '16' - inputs.start_time?.substr(0, 2) < 5 ? (
              <option value="16:00">16:00</option>
            ) : (
              ''
            )}
            {'17' - inputs.start_time?.substr(0, 2) < 5 ? (
              <option value="17:00">17:00</option>
            ) : (
              ''
            )}
          </select>
        </div>
        <small className="text-red-500">{errorsList.end_time}</small>
      </div>
      <div className="">
        <label>{t("subject")}</label>
        <div className="flex flex-col">
          <select
            name="subject_id"
            onChange={handleInput}
            value={inputs.subject_id}
            className="border p-2 rounded"
          >
            <option value="">{t("select subject")}</option>
            {subjectsList.map((item) => {
              return (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <small className="text-red-500">{errorsList.subject_id}</small>
      </div>
      <div className="">
        <label>{t("class")}</label>
        <div className="flex flex-col">
          <select
            name="class_id"
            onChange={handleInput}
            value={inputs.class_id}
            className="border p-2 rounded"
          >
            <option value="">{t("select class")}</option>
            {classesList.map((item) => {
              return (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <small className="text-red-500">{errorsList.class_id}</small>
      </div>
      <div className="">
        <label>{t("classroom")}</label>
        <div className="flex flex-col">
          <select
            name="classroom_id"
            onChange={handleInput}
            value={inputs.classroom_id}
            className="border p-2 rounded"
          >
            <option value="">{t("select classroom")}</option>
            {classroomsList.map((item) => {
              return (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <small className="text-red-500">{errorsList.classroom_id}</small>
      </div>
      <div className="flex justify-center">
        <button
          type="submit"
          className="bg-blue-500 text-white rounded-full w-[50%] p-2 font-bold"
        >
          {t("submit")}
        </button>
      </div>
    </form>
  );
}
