import { IonIcon } from '@ionic/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { calendar, home, layers, people } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

export default function Home() {
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <Link className="text-gray-500 flex items-center gap-2" to="/home">
          <IonIcon className="text-xl" icon={home} /> {t('home')}
        </Link>
      </div>
      <div className="grid grid-cols-12 justify-center">
        <div className="col-span-12 sm:col-span-10 md:col-span-6 lg:col-span-6 xl:col-span-4">
          <Link to="/classes">
            <div className="shadow-xl flex flex-col gap-2 items-center justify-center m-5 p-4">
              <IonIcon className="text-8xl" icon={layers} />
              <div className="text-center text-2xl">
                <strong>{t('classes')}</strong>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-span-12 sm:col-span-10 md:col-span-6 lg:col-span-6 xl:col-span-4">
          <Link to="/teachers">
            <div className="shadow-xl flex flex-col gap-2 items-center justify-center m-5 p-4">
              <IonIcon className="text-8xl flex-start" icon={people} />
              <div className="text-center text-2xl">
                <strong>{t('teachers')}</strong>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-span-12 sm:col-span-10 md:col-span-6 lg:col-span-6 xl:col-span-4">
          <Link className="text-decoration-none text-dark" to="/classrooms">
            <div className="shadow-xl flex flex-col gap-2 items-center justify-center m-5 p-4">
              <div className="row">
                <div className="align-self-center">
                  <IonIcon className="text-8xl flex-start" icon={layers} />
                </div>
              </div>
              <div className="text-center text-2xl">
                <strong className="fs-4">{t('classrooms')}</strong>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-span-12 sm:col-span-10 md:col-span-6 lg:col-span-6 xl:col-span-4">
          <Link className="text-decoration-none text-dark" to="/subjects">
            <div className="shadow-xl flex flex-col gap-2 items-center justify-center m-5 p-4">
              <div className="row">
                <div className="align-self-center">
                  <IonIcon className="text-8xl flex-start" icon={layers} />
                </div>
              </div>
              <div className="text-center text-2xl">
                <strong className="fs-4">{t('subjects')}</strong>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="text-gray-500 flex items-center gap-2 mt-5">
        <IonIcon className="text-xl" icon={calendar} /> {t('schedules')}
      </div>
      <hr />
      <div className="grid grid-cols-12 justify-center">
        <div className="col-span-12 sm:col-span-10 md:col-span-6 lg:col-span-6 xl:col-span-4">
          <Link
            className="text-decoration-none text-dark"
            to="/schedules/schedules-by-class"
          >
            <div className="shadow-xl flex flex-col gap-2 items-center justify-center m-5 p-4">
              <div className="row">
                <div className="align-self-center">
                  <IonIcon className="text-8xl flex-start" icon={layers} />
                </div>
              </div>
              <div className="text-center text-2xl">
                <strong className="fs-4">{t('classes schedules')}</strong>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-span-12 sm:col-span-10 md:col-span-6 lg:col-span-6 xl:col-span-4">
          <Link
            className="text-decoration-none text-dark"
            to="/schedules/schedules-by-teacher"
          >
            <div className="shadow-xl flex flex-col gap-2 items-center justify-center m-5 p-4">
              <div className="row">
                <div className="align-self-center">
                  <IonIcon className="text-8xl flex-start" icon={people} />
                </div>
              </div>
              <div className="text-center text-2xl">
                <strong className="fs-4">{t('teachers schedules')}</strong>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-span-12 sm:col-span-10 md:col-span-6 lg:col-span-6 xl:col-span-4">
          <Link
            className="text-decoration-none text-dark"
            to="/schedules/schedules-by-classroom"
          >
            <div className="shadow-xl flex flex-col gap-2 items-center justify-center m-5 p-4">
              <div className="row">
                <div className="align-self-center">
                  <IonIcon className="text-8xl flex-start" icon={layers} />
                </div>
              </div>
              <div className="text-center text-2xl">
                <strong className="fs-4">{t('classrooms schedules')}</strong>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
