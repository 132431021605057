import React from 'react';

function LoadingCmp() {
  return (
    <div
      className="flex flex-row justify-center items-center"
      style={{ height: `${80}vh` }}
    >
      <div className="spinner-grow text-blue-500" role="status" />
      <div className="spinner-grow text-gray-100" role="status" />
      <div className="spinner-grow text-green-500" role="status" />
      <div className="spinner-grow text-red-500" role="status" />
      <div className="spinner-grow text-yellow-500" role="status" />
      <div className="spinner-grow text-blue-300" role="status" />
      <div className="spinner-grow text-white" role="status" />
      <div className="spinner-grow text-black" role="status" />
    </div>
  );
}

export default LoadingCmp;
