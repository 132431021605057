import { useLiveQuery } from 'dexie-react-hooks';
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { db } from '../../../db';
import ReactToPrint from 'react-to-print';
import Modal from '../../../components/Modal';
import { IonIcon } from '@ionic/react';
import { home } from 'ionicons/icons';
import LoadingCmp from '../../LoadingCmp';
import TdClassroom from './TdClassroom';
import TdToPrint from './TdToPrintByClassroom';
import '../ToPrint.css';
import AddSessionByClassroomCmp from './AddSessionByClassroomCmp';
import EditSessionByClassroomCmp from './EditSessionByClassroomCmp';
import { useTranslation } from 'react-i18next';

const hours = [
  { hour: 'h_18_19', start: '18:00' },
  { hour: 'h_17_18', start: '17:00' },
  { hour: 'h_16_17', start: '16:00' },
  { hour: 'h_15_16', start: '15:00' },
  { hour: 'h_14_15', start: '14:00' },
  { hour: 'h_13_14', start: '13:00' },
  { hour: 'h_12_13', start: '12:00' },
  { hour: 'h_11_12', start: '11:00' },
  { hour: 'h_10_11', start: '10:00' },
  { hour: 'h_9_10', start: '09:00' },
  { hour: 'h_8_9', start: '08:00' },
  { hour: 'h_7_8', start: '07:00' },
];
export default function ScheduleByClassroom() {
  const { t } = useTranslation();
  const [fontsize, setFontsize] = useState(12);
  
  const handleFontsize = (e) => {
    e.persist();
    setFontsize(e.target.value);
  };

  function organise(item, day, data, setdata) {
    const timeSlotsMap = {
      '07:00_11:00': ['h_7_8', 'h_8_9', 'h_9_10', 'h_10_11'],
      '07:00_10:00': ['h_7_8', 'h_8_9', 'h_9_10'],
      '07:00_09:00': ['h_7_8', 'h_8_9'],
      '07:00_08:00': ['h_7_8'],

      '08:00_12:00': ['h_8_9', 'h_9_10', 'h_10_11', 'h_11_12'],
      '08:00_11:00': ['h_8_9', 'h_9_10', 'h_10_11'],
      '08:00_10:00': ['h_8_9', 'h_9_10'],
      '08:00_09:00': ['h_8_9'],

      '09:00_13:00': ['h_9_10', 'h_10_11', 'h_11_12', 'h_12_13'],
      '09:00_12:00': ['h_9_10', 'h_10_11', 'h_11_12'],
      '09:00_11:00': ['h_9_10', 'h_10_11'],
      '09:00_10:00': ['h_9_10'],

      '10:00_14:00': ['h_10_11', 'h_11_12', 'h_12_13', 'h_13_14'],
      '10:00_13:00': ['h_10_11', 'h_11_12', 'h_12_13'],
      '10:00_12:00': ['h_10_11', 'h_11_12'],
      '10:00_11:00': ['h_10_11'],

      '11:00_15:00': ['h_11_12', 'h_12_13', 'h_13_14', 'h_14_15'],
      '11:00_14:00': ['h_11_12', 'h_12_13', 'h_13_14'],
      '11:00_13:00': ['h_11_12', 'h_12_13'],
      '11:00_12:00': ['h_11_12'],

      '12:00_16:00': ['h_12_13', 'h_13_14', 'h_14_15', 'h_15_16'],
      '12:00_15:00': ['h_12_13', 'h_13_14', 'h_14_15'],
      '12:00_14:00': ['h_12_13', 'h_13_14'],
      '12:00_13:00': ['h_12_13'],

      '13:00_17:00': ['h_13_14', 'h_14_15', 'h_15_16', 'h_16_17'],
      '13:00_16:00': ['h_13_14', 'h_14_15', 'h_15_16'],
      '13:00_15:00': ['h_13_14', 'h_14_15'],
      '13:00_14:00': ['h_13_14'],

      '14:00_18:00': ['h_14_15', 'h_15_16', 'h_16_17', 'h_17_18'],
      '14:00_17:00': ['h_14_15', 'h_15_16', 'h_16_17'],
      '14:00_16:00': ['h_14_15', 'h_15_16'],
      '14:00_15:00': ['h_14_15'],

      '15:00_19:00': ['h_15_16', 'h_16_17', 'h_17_18', 'h_18_19'],
      '15:00_18:00': ['h_15_16', 'h_16_17', 'h_17_18'],
      '15:00_17:00': ['h_15_16', 'h_16_17'],
      '15:00_16:00': ['h_15_16'],

      '16:00_19:00': ['h_16_17', 'h_17_18', 'h_18_19'],
      '16:00_18:00': ['h_16_17', 'h_17_18'],
      '16:00_17:00': ['h_16_17'],

      '17:00_19:00': ['h_17_18', 'h_18_19'],
      '17:00_18:00': ['h_17_18'],

      '18:00_19:00': ['h_18_19'],
    };

    const timeKey = `${item.start_time}_${item.end_time}`;
    if (item.weekday == day && timeSlotsMap[timeKey]) {
      const slotsToUpdate = timeSlotsMap[timeKey].reduce((acc, slot) => {
        acc[slot] = item;
        return acc;
      }, {});
      setdata((data) => ({
        ...data,
        ...slotsToUpdate,
      }));
    }
  }
  const componentRef = useRef();
  const [monday, setMonday] = useState({
    h_7_8: {},
    h_8_9: {},
    h_9_10: {},
    h_10_11: {},
    h_11_12: {},
    h_12_13: {},
    h_13_14: {},
    h_14_15: {},
    h_15_16: {},
    h_16_17: {},
    h_17_18: {},
    h_18_19: {},
  });

  const [tuesday, setTuesday] = useState({
    h_7_8: {},
    h_8_9: {},
    h_9_10: {},
    h_10_11: {},
    h_11_12: {},
    h_12_13: {},
    h_13_14: {},
    h_14_15: {},
    h_15_16: {},
    h_16_17: {},
    h_17_18: {},
    h_18_19: {},
  });

  const [wednesday, setWednesday] = useState({
    h_7_8: {},
    h_8_9: {},
    h_9_10: {},
    h_10_11: {},
    h_11_12: {},
    h_12_13: {},
    h_13_14: {},
    h_14_15: {},
    h_15_16: {},
    h_16_17: {},
    h_17_18: {},
    h_18_19: {},
  });

  const [thursday, setThursday] = useState({
    h_7_8: {},
    h_8_9: {},
    h_9_10: {},
    h_10_11: {},
    h_11_12: {},
    h_12_13: {},
    h_13_14: {},
    h_14_15: {},
    h_15_16: {},
    h_16_17: {},
    h_17_18: {},
    h_18_19: {},
  });

  const [friday, setFriday] = useState({
    h_7_8: {},
    h_8_9: {},
    h_9_10: {},
    h_10_11: {},
    h_11_12: {},
    h_12_13: {},
    h_13_14: {},
    h_14_15: {},
    h_15_16: {},
    h_16_17: {},
    h_17_18: {},
    h_18_19: {},
  });

  const [saturday, setSaturday] = useState({
    h_7_8: {},
    h_8_9: {},
    h_9_10: {},
    h_10_11: {},
    h_11_12: {},
    h_12_13: {},
    h_13_14: {},
    h_14_15: {},
    h_15_16: {},
    h_16_17: {},
    h_17_18: {},
    h_18_19: {},
  });
  const [toAdd, setToAdd] = useState({});
  const [toEdit, setToEdit] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const [classroom, setClassroom] = useState({});

  useEffect(() => {
    db.classrooms.get(Number.parseInt(id)).then((res) => {
      if (res) setClassroom(res);
    });
  }, [id]);

  const [itemsList, setItemsList] = useState([]);

  const setToAddChild = (item) => {
    setToAdd(item);
  };
  const setToAddModal = (bool) => {
    setModalShow(bool);
  };

  const setToEditChild = (item) => {
    setToEdit(item);
  };
  const setToEditModal = (bool) => {
    setEditModalShow(bool);
  };

  const setItems = (items) => {
    setItemsList(items);
  };

  const sessions = useLiveQuery(() =>
    db.sessions.where('classroom_id').equals(Number.parseInt(id)).toArray()
  );
  useEffect(() => {
    if (sessions) {
      setItemsList(sessions);
      setLoading(false);
    } else {
      setItemsList([]);
      setLoading(false);
    }
  }, [sessions]);

  useEffect(() => {
    setMonday({
      h_7_8: {},
      h_8_9: {},
      h_9_10: {},
      h_10_11: {},
      h_11_12: {},
      h_12_13: {},
      h_13_14: {},
      h_14_15: {},
      h_15_16: {},
      h_16_17: {},
      h_17_18: {},
      h_18_19: {},
    });
    setTuesday({
      h_7_8: {},
      h_8_9: {},
      h_9_10: {},
      h_10_11: {},
      h_11_12: {},
      h_12_13: {},
      h_13_14: {},
      h_14_15: {},
      h_15_16: {},
      h_16_17: {},
      h_17_18: {},
      h_18_19: {},
    });
    setWednesday({
      h_7_8: {},
      h_8_9: {},
      h_9_10: {},
      h_10_11: {},
      h_11_12: {},
      h_12_13: {},
      h_13_14: {},
      h_14_15: {},
      h_15_16: {},
      h_16_17: {},
      h_17_18: {},
      h_18_19: {},
    });
    setThursday({
      h_7_8: {},
      h_8_9: {},
      h_9_10: {},
      h_10_11: {},
      h_11_12: {},
      h_12_13: {},
      h_13_14: {},
      h_14_15: {},
      h_15_16: {},
      h_16_17: {},
      h_17_18: {},
      h_18_19: {},
    });
    setFriday({
      h_7_8: {},
      h_8_9: {},
      h_9_10: {},
      h_10_11: {},
      h_11_12: {},
      h_12_13: {},
      h_13_14: {},
      h_14_15: {},
      h_15_16: {},
      h_16_17: {},
      h_17_18: {},
      h_18_19: {},
    });
    setSaturday({
      h_7_8: {},
      h_8_9: {},
      h_9_10: {},
      h_10_11: {},
      h_11_12: {},
      h_12_13: {},
      h_13_14: {},
      h_14_15: {},
      h_15_16: {},
      h_16_17: {},
      h_17_18: {},
      h_18_19: {},
    });
    itemsList.map((item) => {
      // monday
      organise(item, 'Monday', monday, setMonday);
      // tuesday
      organise(item, 'Tuesday', tuesday, setTuesday);
      // wednesday
      organise(item, 'Wednesday', wednesday, setWednesday);
      // thursday
      organise(item, 'Thursday', thursday, setThursday);
      // friday
      organise(item, 'Friday', friday, setFriday);
      // saturday
      organise(item, 'Saturday', saturday, setSaturday);
    });
  }, [itemsList]);


  if (loading) {
    return <LoadingCmp />;
  }

  return (
    <div className="max-w-7xl mx-auto pb-4 flex flex-col gap-2">
      <div className="text-gray-500 flex items-center gap-2">
        <Link className="flex items-center gap-2" to="/home">
          <IonIcon className="text-xl" icon={home} /> {t('home')}
        </Link>{' '}
        {'>>'}{' '}
        <Link className="text-gray-500" to="/schedules">
          {' '}
          {t('schedules')}{' '}
        </Link>{' '}
        {'>>'}{' '}
        <Link className="text-gray-500" to="/schedules/schedules-by-classroom">
          {t('classrooms schedules')}
        </Link>{' '}
        {'>>'} {classroom.name ? classroom.name : ''}{' '}
      </div>
      <div className="flex justify-between">
        <div className="m-2 mb-4 flex gap-2 items-center font-semibold">
          <label>{t("class")}</label>
          <div className="flex flex-col">
            <select
              name="classroom_id"
              onChange={handleFontsize}
              value={fontsize}
              className="border px-3 py-2"
            >
              <option value={16}>16</option>
              <option value={14}>14</option>
              <option value={12}>12</option>
            </select>
          </div>
        </div>
        <ReactToPrint
          trigger={() => (
            <button
              type="button"
              className="bg-blue-500 px-4 py-2 rounded text-white font-bold m-2"
            >
              {t("print")}
            </button>
          )}
          content={() => componentRef.current}
        />
      </div>
      <div className="overflow-x-auto mx-auto">
        <table className="border border-collapse text-center">
          <thead>
            <tr className="bg-gray-100">
              <th className="border px-3 py-2">19 - 18</th>
              <th className="border px-3 py-2">18 - 17</th>
              <th className="border px-3 py-2">17 - 16</th>
              <th className="border px-3 py-2">16 - 15</th>
              <th className="border px-3 py-2">15 - 14</th>
              <th className="border px-3 py-2">14 - 13</th>
              <th className="border px-3 py-2">13 - 12</th>
              <th className="border px-3 py-2">12 - 11</th>
              <th className="border px-3 py-2">11 - 10</th>
              <th className="border px-3 py-2">10 - 9</th>
              <th className="border px-3 py-2">9 - 8</th>
              <th className="border px-3 py-2">8 - 7</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr>
              {Object.keys(monday).map((session, index) => (
                <>
                  <TdClassroom
                    key={`Monday${hours[index].start}`}
                    setitems={setItems}
                    settoadd={setToAddChild}
                    settoaddmodal={setToAddModal}
                    settoedit={setToEditChild}
                    settoeditmodal={setToEditModal}
                    data={{
                      classroom_id: id,
                      weekday: 'Monday',
                      start_time: hours[index].start,
                    }}
                    item={monday[hours[index].hour]}
                  />
                </>
              ))}
              <th className="align-middle">الإثنين</th>
            </tr>
            <tr>
              {Object.keys(tuesday).map((session, index) => (
                <TdClassroom
                  key={`Tuesday${hours[index].start}`}
                  setitems={setItems}
                  settoadd={setToAddChild}
                  settoaddmodal={setToAddModal}
                  settoedit={setToEditChild}
                  settoeditmodal={setToEditModal}
                  data={{
                    classroom_id: id,
                    weekday: 'Tuesday',
                    start_time: hours[index].start,
                  }}
                  item={tuesday[hours[index].hour]}
                />
              ))}
              <th className="align-middle">الثلاثاء</th>
            </tr>
            <tr>
              {Object.keys(wednesday).map((session, index) => (
                <TdClassroom
                  key={`Wednesday${hours[index].start}`}
                  setitems={setItems}
                  settoadd={setToAddChild}
                  settoaddmodal={setToAddModal}
                  settoedit={setToEditChild}
                  settoeditmodal={setToEditModal}
                  data={{
                    classroom_id: id,
                    weekday: 'Wednesday',
                    start_time: hours[index].start,
                  }}
                  item={wednesday[hours[index].hour]}
                />
              ))}
              <th className="align-middle">الإربعاء</th>
            </tr>
            <tr>
              {Object.keys(thursday).map((session, index) => (
                <TdClassroom
                  key={`Thursday${hours[index].start}`}
                  setitems={setItems}
                  settoadd={setToAddChild}
                  settoaddmodal={setToAddModal}
                  settoedit={setToEditChild}
                  settoeditmodal={setToEditModal}
                  data={{
                    classroom_id: id,
                    weekday: 'Thursday',
                    start_time: hours[index].start,
                  }}
                  item={thursday[hours[index].hour]}
                />
              ))}
              <th className="align-middle">الخميس</th>
            </tr>
            <tr>
              {Object.keys(friday).map((session, index) => (
                <TdClassroom
                  key={`Friday${hours[index].start}`}
                  setitems={setItems}
                  settoadd={setToAddChild}
                  settoaddmodal={setToAddModal}
                  settoedit={setToEditChild}
                  settoeditmodal={setToEditModal}
                  data={{
                    classroom_id: id,
                    weekday: 'Friday',
                    start_time: hours[index].start,
                  }}
                  item={friday[hours[index].hour]}
                />
              ))}
              <th className="align-middle">الجمعة</th>
            </tr>
            <tr>
              {Object.keys(saturday).map((session, index) => (
                <TdClassroom
                  key={`Saturday${hours[index].start}`}
                  setitems={setItems}
                  settoadd={setToAddChild}
                  settoaddmodal={setToAddModal}
                  settoedit={setToEditChild}
                  settoeditmodal={setToEditModal}
                  data={{
                    classroom_id: id,
                    weekday: 'Saturday',
                    start_time: hours[index].start,
                  }}
                  item={saturday[hours[index].hour]}
                />
              ))}
              <th className="align-middle">السبت</th>
            </tr>
          </tbody>
        </table>
      </div>
      <Modal
        show={modalShow}
        hide={() => {
          setModalShow(false);
        }}
        dialogClassName="w-full sm:max-w-2xl h-fit my-auto pb-5 rounded-xl"
      >
        <AddSessionByClassroomCmp toadd={toAdd} />
      </Modal>
      <Modal
        show={editModalShow}
        hide={() => {
          setEditModalShow(false);
        }}
        dialogClassName="w-full sm:max-w-2xl h-fit my-auto pb-5 rounded-xl"
      >
        <EditSessionByClassroomCmp toedit={toEdit} />
      </Modal>
      <div className="print-source">
        <div
          ref={componentRef}
          className="container d-flex align-items-center justify-content-center p-5"
          style={{
            height: '100%',
            width: '100%',
            fontSize: `${fontsize}px`,
          }}
        >
          <img
            src="/assets/logo-andalus-with-name.png"
            width="120"
            height="60"
            alt=""
            className="absolute top-5 left-5"
          />
          <div>
            <div className="text-center mb-4">
              {classroom.name ? classroom.name : ''}
            </div>
            <div className="overflow-x-auto mx-auto">
              <table className="border border-collapse text-center">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="border px-3 py-2">19 - 18</th>
                    <th className="border px-3 py-2">18 - 17</th>
                    <th className="border px-3 py-2">17 - 16</th>
                    <th className="border px-3 py-2">16 - 15</th>
                    <th className="border px-3 py-2">15 - 14</th>
                    <th className="border px-3 py-2">14 - 13</th>
                    <th className="border px-3 py-2">13 - 12</th>
                    <th className="border px-3 py-2">12 - 11</th>
                    <th className="border px-3 py-2">11 - 10</th>
                    <th className="border px-3 py-2">10 - 9</th>
                    <th className="border px-3 py-2">9 - 8</th>
                    <th className="border px-3 py-2">8 - 7</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {Object.keys(monday).map((session, index) => (
                      <TdToPrint
                        key={`Monday${monday[hours[index].hour]}`}
                        item={monday[hours[index].hour]}
                      />
                    ))}
                    <th className="align-middle px-3 py-2 border">الإثنين</th>
                  </tr>
                  <tr>
                    {Object.keys(tuesday).map((session, index) => (
                      <TdToPrint
                        key={`Tuesday${monday[hours[index].hour]}`}
                        item={tuesday[hours[index].hour]}
                      />
                    ))}
                    <th className="align-middle px-3 py-2 border">الثلاثاء</th>
                  </tr>
                  <tr>
                    {Object.keys(wednesday).map((session, index) => (
                      <TdToPrint
                        key={`Wednesday${monday[hours[index].hour]}`}
                        item={wednesday[hours[index].hour]}
                      />
                    ))}
                    <th className="align-middle px-3 py-2 border">الإربعاء</th>
                  </tr>
                  <tr>
                    {Object.keys(thursday).map((session, index) => (
                      <TdToPrint
                        key={`Thursday${monday[hours[index].hour]}`}
                        item={thursday[hours[index].hour]}
                      />
                    ))}
                    <th className="align-middle px-3 py-2 border">الخميس</th>
                  </tr>
                  <tr>
                    {Object.keys(friday).map((session, index) => (
                      <TdToPrint
                        key={`Friday${monday[hours[index].hour]}`}
                        item={friday[hours[index].hour]}
                      />
                    ))}
                    <th className="align-middle px-3 py-2 border">الجمعة</th>
                  </tr>
                  <tr>
                    {Object.keys(saturday).map((session, index) => (
                      <TdToPrint
                        key={`Saturday${monday[hours[index].hour]}`}
                        item={saturday[hours[index].hour]}
                      />
                    ))}
                    <th className="align-middle px-3 py-2 border">السبت</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
