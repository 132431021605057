import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  bookmark,
  calendar,
  download,
  home,
  layers,
  people,
} from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { useTranslation } from 'react-i18next';

export default function Sidebare() {
  const { t } = useTranslation()

  return (
    <div className="sidebar bg-white shadow z-10">
      <NavLink to="/home">
        <div className="flex items-center gap-2">
          <IonIcon className="text-xl" icon={home} /> {t('home')}
        </div>
      </NavLink>
      <NavLink to="/classes">
        <div className="flex items-center gap-2">
          <IonIcon className="text-xl" icon={layers} /> {t('classes')}
        </div>
      </NavLink>
      <NavLink to="/teachers">
        <div className="flex items-center gap-2">
          <IonIcon className="text-xl" icon={people} /> {t('teachers')}
        </div>
      </NavLink>
      <NavLink to="/classrooms">
        <div className="flex items-center gap-2">
          <IonIcon className="text-xl" icon={layers} /> {t("classrooms")}
        </div>
      </NavLink>
      <NavLink to="/subjects">
        <div className="flex items-center gap-2">
          <IonIcon className="text-xl" icon={layers} /> {t("subjects")}
        </div>
      </NavLink>
      <NavLink to="/schedules">
        <div className="flex items-center gap-2">
          <IonIcon className="text-xl" icon={calendar} /> {t("schedules")}
        </div>
      </NavLink>
      <NavLink to="/badge">
        <div className="flex items-center gap-2">
          <IonIcon className="text-xl" icon={bookmark} /> {t("badges")}
        </div>
      </NavLink>
      <NavLink to="/export">
        <div className="flex items-center gap-2">
          <IonIcon className="text-xl" icon={download} /> {t("export")}
        </div>
      </NavLink>
    </div>
  );
}
