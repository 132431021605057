import React, { useEffect, useState } from 'react';
import { db } from '../../db';
import Swal from 'sweetalert2';
import LoadingCmp from '../LoadingCmp';
import Modal from '../../components/Modal';
import { useTranslation } from 'react-i18next';

export default function EditSubjectCmp(props) {
  const { t } = useTranslation()
  const [inputs, setItem] = useState({
    name: '',
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.toedit) {
      setItem(props.toedit);
      setLoading(false);
    }
  }, [props.toedit]);

  const [errorsList, setErrors] = useState([]);

  const handleInput = (e) => {
    e.persist();
    setItem({ ...inputs, [e.target.name]: e.target.value });
  };

  const updateItem = async (e) => {
    e.preventDefault();
    setErrors({});
    const data = {
      name: inputs.name.trim(),
    };
    if (!data.name)
      setErrors((errors) => ({
        ...errors,
        name: t("name required"),
      }));
    else
      try {
        await db.subjects.update(props.toedit.id, data);
        setErrors({});
        Swal.fire(t("success"), t("subject updated successfully"), 'success');
      } catch (error) {
        setErrors((errors) => ({ ...errors, name: 'Nom existe déjà' }));
      }
  };
  if (loading) {
    return <LoadingCmp />;
  }
  return (
    <Modal
      show={props.show}
      hide={props.hide}
      dialogClassName="w-full sm:max-w-2xl h-fit my-auto pb-5 rounded-xl"
      afterLeave={props.afterLeave}
    >
      <div className="border-b mb-5 p-4 rounded-t-xl bg-gray-100 font-bold text-xl">
        Modifier Matière
      </div>
      <form onSubmit={updateItem} className="flex flex-col gap-4 px-6">
        <div className="flex flex-col">
          <label>{t('name')}</label>
          <input
            type="text"
            name="name"
            onChange={handleInput}
            value={inputs.name}
            className="p-2 border outline-none ring-gray-200 rounded focus:ring focus:ring-blue-400 transition duration-200"
          />
          <small className="text-red-500">{errorsList.name}</small>
        </div>

        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-blue-500 text-white rounded-full w-[50%] p-2 font-bold"
          >
            {t("submit")}
          </button>
        </div>
      </form>
    </Modal>
  );
}
