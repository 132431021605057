import React from 'react';
import { useTranslation } from 'react-i18next';

export default function BadgeCmp({ badge }) {
  const { t } = useTranslation();
  return (
    <div className="">
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-5 flex items-center">
          <img src="/logo-andalus-with-name.png" alt='logo'/>
        </div>
        <div className="col-span-7 bg-[#17bef1] text-white text-end font-bold text-2xl flex items-center justify-center py-2">
          بطاقة تعريف مدرسية
        </div>
        <div className="col-span-5"></div>
        <div className="col-span-7  text-[#17bef1] text-end font-bold text-xl">
          السنة الدراسية 2024/2023
        </div>
        <div className="col-span-5"></div>
        <div className="col-span-7 text-xl text-end">{badge?.name} :الإسم</div>
        <div className="col-span-5"></div>
        <div className="col-span-7 text-xl text-end">
          {badge?.lastname} :اللقب
        </div>
        <div className="col-span-5"></div>
        <div className="col-span-7 text-xl text-end">
          {badge?.date} :تاريخ الولادة
        </div>
        <div className="col-span-5"></div>
        <div className="col-span-7 text-xl text-end">{badge?.class} :القسم</div>
        <div className="col-span-5"></div>
        <div className="col-span-7 text-xl text-end">
          {badge?.number} :رقم التسجيل
        </div>
        <div className="col-span-5"></div>
        <div className="col-span-7 text-xl text-end">
          {badge?.address} :العنوان
        </div>
        <div className="col-span-5"></div>
        <div className="col-span-7 text-xl text-end">
          {badge?.phone} :الهاتف
        </div>
      </div>
    </div>
  );
}
