import { useLiveQuery } from 'dexie-react-hooks';
import React, { useEffect, useRef, useState } from 'react';
import { db } from '../../../db';
import ReactToPrint from 'react-to-print';
import TdToPrintByClassroom from '../byclassroom/TdToPrintByClassroom';
import './ToPrintSummary.css';
import TdToPrintSummary from './TdToPrintSummary';
import { useTranslation } from 'react-i18next';

function getTimeSlot(hour) {
  return `${hour}:00_${hour + 1}:00`;
}

export default function Summary() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [itemsList, setItemsList] = useState([]);
  const [classroomsList, setClassrooms] = useState([]);

  const sessions = useLiveQuery(() => db.sessions.toArray());

  useEffect(() => {
    if (sessions) {
      const organizedSessions = sessions.reduce((result, session) => {
        const { classroom_id, weekday, start_time, end_time } = session;
        const startTimeParts = start_time.split(':');
        const endTimeParts = end_time.split(':');

        const startHour = parseInt(startTimeParts[0]);
        const endHour = parseInt(endTimeParts[0]);

        for (let hour = startHour; hour < endHour; hour++) {
          const timeSlot = `${hour}:00_${hour + 1}:00`;

          if (!result[classroom_id]) {
            result[classroom_id] = {};
          }

          if (!result[classroom_id][weekday]) {
            result[classroom_id][weekday] = {};
          }

          if (!result[classroom_id][weekday][timeSlot]) {
            result[classroom_id][weekday][timeSlot] = [];
          }

          result[classroom_id][weekday][timeSlot].push(session);
        }

        return result;
      }, {});
      setItemsList(organizedSessions);
    }
  }, [sessions]);

  const classrooms = useLiveQuery(() => db.classrooms.toArray());
  useEffect(() => {
    if (classrooms) {
      setClassrooms(classrooms);
      setLoading(false);
    }
  }, [classrooms]);

  const weekdays = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const hours = Array.from({ length: 12 }, (_, index) => index + 7);

  const componentRef = useRef();

  if (loading) return <div>loading</div>;

  return (
    <>
      <table className="text-center">
        <thead>
          <tr className="border">
            <th className="border min-w-[50px]" />
            {weekdays.map((day) => (
              <th className="border" colSpan={12}>
                {t(day)}
              </th>
            ))}
          </tr>
          <tr className="border">
            <th className="border min-w-[50px]" />
            {weekdays.map((day) => (
              <>
                {hours.map((hour) => (
                  <th className="border min-w-[50px]">
                    {`${hour}-${Number(hour + 1)}`}
                  </th>
                ))}
              </>
            ))}
          </tr>
        </thead>
        <tbody>
          {classroomsList.map((classroom) => (
            <tr className="border">
              <th className="border">{classroom.name}</th>
              {weekdays.map((weekday) => (
                <>
                  {hours.map((hour) => (
                    <TdToPrintByClassroom
                      item={
                        itemsList?.[classroom.id]?.[weekday]?.[
                          getTimeSlot(hour)
                        ]?.[0]
                          ? itemsList?.[classroom.id]?.[weekday]?.[
                              getTimeSlot(hour)
                            ]?.[0]
                          : {}
                      }
                    />
                  ))}
                </>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <ReactToPrint
        trigger={() => (
          <button
            type="button"
            className="bg-blue-500 px-4 py-2 rounded text-white font-bold m-2"
          >
            {t('print')}
          </button>
        )}
        content={() => componentRef.current}
      />
      <div className="print-source">
        <div
          ref={componentRef}
          className="mx-auto max-w-7xl flex items-center justify-center p-5"
          style={{
            height: '100%',
            width: '100%',
            fontSize: '4px',
          }}
        >
          <table className="text-center">
            <thead>
              <tr className="border">
                <th className="border min-w-[20px]" />
                {weekdays.map((day) => (
                  <th className="border" colSpan={12}>
                    {day}
                  </th>
                ))}
              </tr>
              <tr className="border">
                <th className="border min-w-[20px]" />
                {weekdays.map((day) => (
                  <>
                    {hours.map((hour) => (
                      <th className="border min-w-[20px]">
                        {`${hour}-${Number(hour + 1)}`}
                      </th>
                    ))}
                  </>
                ))}
              </tr>
            </thead>
            <tbody>
              {classroomsList.map((classroom) => (
                <tr className="border">
                  <th className="border">{classroom.name}</th>
                  {weekdays.map((weekday) => (
                    <>
                      {hours.map((hour) => (
                        <TdToPrintSummary
                          item={
                            itemsList?.[classroom.id]?.[weekday]?.[
                              getTimeSlot(hour)
                            ]?.[0]
                              ? itemsList?.[classroom.id]?.[weekday]?.[
                                  getTimeSlot(hour)
                                ]?.[0]
                              : {}
                          }
                        />
                      ))}
                    </>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
