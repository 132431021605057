import { useLiveQuery } from 'dexie-react-hooks';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../db';
import Swal from 'sweetalert2';
import { IonIcon } from '@ionic/react';
import { create, trash, home } from 'ionicons/icons';
import LoadingCmp from '../LoadingCmp';
import AddSubjectCmp from './AddSubjectCmp';
import EditSubjectCmp from './EditSubjectCmp';
import { useTranslation } from 'react-i18next';

export default function Subjects() {
  const { i18n, t } = useTranslation()
  const [loading, setLoading] = useState(true);
  const [itemsList, setItemsList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [toEdit, setToEdit] = useState({});
  const subjects = useLiveQuery(() => db.subjects.toArray());
  useEffect(() => {
    if (subjects) {
      setItemsList(subjects);
      setLoading(false);
    }
  }, [subjects]);

  const deleteItem = async (e, item) => {
    e.preventDefault();

    Swal.fire({
      title: t("delete subject"),
      text: i18n.language == "ar" ? `${t("delete verification")}: ${item.name}` : `${t("delete verification")} "${item.name}" ?`,
      showDenyButton: true,
      confirmButtonText: t("delete"),
      denyButtonText: t("cancel"),
      confirmButtonColor: '#df4759',
      denyButtonColor: '#d9e2ef',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await db.subjects.delete(item.id);
        Swal.fire(t("success"), t("subject deleted successfully"), 'success');
      } else if (result.isDenied) {
      }
    });
  };
  if (loading) {
    return <LoadingCmp />;
  }
  let itemsHtml = '';
  if (itemsList.length > 0) {
    itemsHtml = (
      <>
        <div className="shadow-xl">
          <div className="items-center justify-between flex p-3 bg-gray-100 font-semibold">
            <h5 className="mb-3">{t('subjects')} ({itemsList.length})</h5>
            <button
              type="button"
              className="bg-blue-500 text-white p-2 rounded text-sm"
              onClick={() => {
                setModalShow(true);
              }}
            >
              {t('add subject')}
            </button>
          </div>

          <div className="px-4">
            <div className="grid grid-cols-12">
              <div className="py-2 col-span-6">{t('name')}</div>
              <div className="py-2 col-span-6 text-center">{t('actions')}</div>
            </div>
            {itemsList.map((item, idx) => {
              return (
                <div className="mx-0 border-b grid grid-cols-12" key={idx}>
                  <div className="col-span-6 flex items-center">
                    <h5 className="text-black">
                      <strong>{item.name}</strong>
                    </h5>
                  </div>
                  <div className="py-3 col-span-6 text-center">
                    <div className="grid grid-cols-12">
                      <div className="col-span-6">
                        <button
                          disabled={item.id == 1}
                          type="button"
                          className=""
                          onClick={(e) => deleteItem(e, item)}
                        >
                          <IonIcon
                            className={`text-3xl jusitfy-start ${
                              item.id != 1 ? 'text-red-500' : 'text-gray-300'
                            } `}
                            icon={trash}
                          />
                        </button>
                      </div>
                      <div className="col-span-6">
                        <button
                          disabled={item.id == 1}
                          type="button"
                          className=""
                          onClick={() => {
                            setToEdit(item);
                            setEditModalShow(true);
                          }}
                        >
                          <IonIcon
                            className={`text-3xl jusitfy-start ${
                              item.id != 1 ? 'text-blue-500' : 'text-gray-300'
                            } `}
                            icon={create}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  } else {
    itemsHtml = (
      <div className="shadow-xl">
        <div className="items-center justify-between flex p-3 bg-gray-100 font-semibold">
          <h5 className="mb-3">{t('subjects')} ({itemsList.length})</h5>
          <button
            type="button"
            className="bg-blue-500 text-white p-2 rounded text-sm"
            onClick={() => {
              setModalShow(true);
            }}
          >
            {t('add subject')}
          </button>
        </div>
        <div className="py-32 text-center font-semibold">
          <h4>{t("no subjects yet")}</h4>
        </div>
      </div>
    );
  }
  return (
    <div className="py-2">
      <div className="text-gray-500 flex items-center gap-2">
        <Link className="flex items-center gap-2" to="/home">
          <IonIcon className="text-xl" icon={home} /> {t('home')}
        </Link>{' '}
        {'>>'} {t('subjects')}
      </div>
      <div className="mx-auto max-w-5xl p-0 sm:p-1 md:p-2 lg:p-4 xl:p-5">
        {itemsHtml}
      </div>
      <AddSubjectCmp
        show={modalShow}
        hide={() => {
          setModalShow(false);
        }}
      />
      <EditSubjectCmp
        toedit={toEdit}
        show={editModalShow}
        hide={() => {
          setEditModalShow(false);
        }}
      />
    </div>
  );
}
