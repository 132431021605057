import Modal from '../../components/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { db } from '../../db';
import Swal from 'sweetalert2';

export default function AddClassroomCmp({ show, hide, afterLeave }) {
  const { t } = useTranslation();
  const [classroomInput, setClassroom] = useState({
    name: '',
    remark: '',
    status: 1,
  });
  const [errorsList, setErrors] = useState([]);
  function resetInputs() {
    setClassroom({
      name: '',
      remark: '',
    });
    setErrors([]);
  }
  const handleInput = (e) => {
    e.persist();
    setClassroom({ ...classroomInput, [e.target.name]: e.target.value });
  };

  const submitclassNameroom = async (e) => {
    e.preventDefault();
    setErrors({});
    const data = {
      name: classroomInput.name.trim(),
      remark: classroomInput.remark,
      status: 1,
    };
    if (!data.name)
      setErrors((errors) => ({
        ...errors,
        name: t("name required"),
      }));
    else
      try {
        // Add the new friend!
        await db.classrooms.add(data);
        resetInputs();
        Swal.fire(t("success"), t("classroom updated successfully"), 'success');
      } catch (error) {
        error.inner.code == 0
          ? setErrors((errors) => ({ ...errors, name: 'Nom existe déjà' }))
          : Swal.fire('Erreur', error.inner.message, 'error');
      }
  };
  return (
    <Modal
      show={show}
      hide={hide}
      dialogClassName="w-full sm:max-w-2xl h-fit my-auto pb-5 rounded-xl"
      afterLeave={afterLeave}
    >
      <div className="border-b mb-5 p-4 rounded-t-xl bg-gray-100 font-bold text-xl">
      {t('add classroom')}
      </div>
      <form onSubmit={submitclassNameroom} className="flex flex-col gap-4 px-6">
        <div className="flex flex-col">
          <label>{t('name')}</label>
          <input
            type="text"
            name="name"
            onChange={handleInput}
            value={classroomInput.name}
            className="p-2 border outline-none ring-gray-200 rounded focus:ring focus:ring-blue-400 transition duration-200"
          />
          <small className="text-red-500">{errorsList.name}</small>
        </div>
        <div className="flex flex-col">
          <label>{t('notes')}</label>
          <textarea
            name="remark"
            onChange={handleInput}
            value={classroomInput.remark}
            className="p-2 border outline-none ring-gray-200 rounded focus:ring focus:ring-blue-400 transition duration-200"
          />
          <small className="text-danger">{errorsList.remark}</small>
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-blue-500 text-white rounded-full w-[50%] p-2 font-bold"
          >
            {t("submit")}
          </button>
        </div>
      </form>
    </Modal>
  );
}
