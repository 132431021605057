import Modal from '../../components/Modal';
import { useLiveQuery } from 'dexie-react-hooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { db } from '../../db';
import Swal from 'sweetalert2';

export default function AddClassCmp({ show, hide, afterLeave }) {
  const { t } = useTranslation();
  const [allcheckbox, setAllcheckbox] = useState({});
  const handleCheckbox = (e) => {
    e.persist();
    setAllcheckbox({ ...allcheckbox, [e.target.name]: e.target.checked });
  };
  const [classesList, setClasses] = useState([]);
  const classes = useLiveQuery(() =>
    db.classes.where({ class_1_id: '' }).toArray()
  );

  useEffect(() => {
    if (classes) {
      setClasses(classes);
    }
  }, [classes]);

  const [inputs, setItem] = useState({
    name: '',
    class_1_id: '',
    class_2_id: '',
  });
  const [errorsList, setErrors] = useState([]);
  function resetInputs() {
    setItem({
      name: '',
      class_1_id: '',
      class_2_id: '',
    });
    setErrors({});
  }

  const handleInput = (e) => {
    e.persist();
    setItem({ ...inputs, [e.target.name]: e.target.value });
  };

  const submitItem = async (e) => {
    e.preventDefault();
    setErrors({});
    const data = {
      name: inputs.name.trim(),
      class_1_id:
        allcheckbox.group == 1 ? Number.parseInt(inputs.class_1_id) : '',
      class_2_id:
        allcheckbox.group == 1 ? Number.parseInt(inputs.class_2_id) : '',
    };
    if (!data.name)
      setErrors((errors) => ({
        ...errors,
        name: t("name required"),
      }));
    if (!data.class_1_id ^ !data.class_2_id)
      setErrors((errors) => ({
        ...errors,
        class_2_id: 'On peut pas selectionner une seule classe',
        class_1_id: 'On peut pas selectionner une seule classe',
      }));
    if (data.name && !(!data.class_1_id ^ !data.class_2_id))
      try {
        // Add the new friend!
        await db.classes.add(data);
        resetInputs();
        Swal.fire(t("success"), t("class added successfully"), 'success');
      } catch (error) {
        error.inner.code == 0
          ? setErrors((errors) => ({ ...errors, name: 'Nom existe déjà' }))
          : Swal.fire('Erreur', error.inner.message, 'error');
      }
  };
  return (
    <Modal
      show={show}
      hide={hide}
      dialogClassName="w-full sm:max-w-2xl h-fit my-auto pb-5 rounded-xl"
      afterLeave={afterLeave}
    >
      <div className="border-b mb-5 p-4 rounded-t-xl bg-gray-100 font-bold text-xl">
      {t('add class')}
      </div>
      <form onSubmit={submitItem} className="flex flex-col gap-4 px-6">
        <div className="flex flex-col">
          <label>{t('name')}</label>
          <input
            type="text"
            name="name"
            onChange={handleInput}
            value={inputs.name}
            className="p-2 border outline-none ring-gray-200 rounded focus:ring focus:ring-blue-400 transition duration-200"
          />
          <small className="text-red-500">{errorsList.name}</small>
        </div>
        <div className="flex gap-2 items-center">
          <label>{t("group of classes")}</label>
          <input
            type="checkbox"
            name="group"
            onChange={handleCheckbox}
            defaultChecked={allcheckbox.group === 1}
            className=""
          />
        </div>
        {allcheckbox.group ? (
          <div className="">
            <label>{t('class 1')}</label>
            <div className="flex flex-col">
              <select
                name="class_1_id"
                onChange={handleInput}
                value={inputs.class_1_id}
                className="border p-2"
              >
                <option value="">{t("select class 1")}</option>
                {classesList.map((item) => {
                  return item.id != inputs.class_2_id ? (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ) : (
                    ''
                  );
                })}
              </select>
            </div>
            <small className="text-red-500">{errorsList.class_1_id}</small>
          </div>
        ) : (
          ''
        )}
        {allcheckbox.group ? (
          <div className="">
            <label>{t("class 2")}</label>
            <div className="flex flex-col">
              <select
                name="class_2_id"
                onChange={handleInput}
                value={inputs.class_2_id}
                className="border p-2"
              >
                <option value="">{t("select class 2")}</option>
                {classesList.map((item) => {
                  return item.id != inputs.class_1_id ? (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ) : (
                    ''
                  );
                })}
              </select>
            </div>
            <small className="text-red-500">{errorsList.class_2_id}</small>
          </div>
        ) : (
          ''
        )}

        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-blue-500 text-white rounded-full w-[50%] p-2 font-bold"
          >
            {t("submit")}
          </button>
        </div>
      </form>
    </Modal>
  );
}
