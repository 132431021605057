import React, { useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import '../schedules/ToPrint.css';
import BadgeCmp from './BadgeCmp';
import { useTranslation } from 'react-i18next';

export default function Badge() {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(0);
  const [badges, setBadges] = useState([
    {
      id: 0,
      name: '',
      lastname: '',
      date: '',
      number: '',
      address: '',
      phone: '',
      permissions: {
        restaurant: 0,
        canGoOut: 0,
        toilet: 0,
      },
    },
  ]);
  const componentRef = useRef();

  const handleSelect = (e) => {
    setSelected(e.target.value - 1);
  };

  const handleInput = (e) => {
    setBadges((prevBadges) => {
      const updatedBadges = prevBadges.map((badge) => {
        if (badge.id === selected) {
          return { ...badge, [e.target.name]: e.target.value };
        }
        return badge;
      });

      return updatedBadges;
    });
  };

  const handleCheckboxes = (e) => {
    setBadges((prevBadges) => {
      return prevBadges.map((badge) => {
        if (badge.id === badges[selected].id) {
          return {
            ...badge,
            permissions: {
              ...badge.permissions,
              [e.target.name]: badge.permissions[e.target.name] ? 0 : 1,
            },
          };
        }
        return badge;
      });
    });
  };

  const addBadge = () => {
    if (badges.length < 8) {
      setBadges((prevBadges) => [
        ...prevBadges,
        {
          id: badges.length,
          name: '',
          lastname: '',
          date: '',
          number: '',
          address: '',
          phone: '',
          permissions: {
            restaurant: 0,
            canGoOut: 0,
            toilet: 0,
          },
        },
      ]);
      setSelected(badges.length);
    }
  };

  return (
    <div>
      <button className="" onClick={addBadge}>
        {t("add badge")}
      </button>

      <select name="badges" onChange={handleSelect} value={selected + 1}>
        {badges.map((badge) => (
          <option key={'option' + badge.id} value={`${badge.id + 1}`}>
            {t("badge")} {badge.id + 1}
          </option>
        ))}
      </select>

      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <label>{t('lastname')}</label>
          <input
            type="text"
            className=""
            name="lastname"
            value={badges[selected]?.lastname}
            onChange={handleInput}
          />
        </div>
        <div className="flex flex-col">
          <label>{t('name')}</label>
          <input
            type="text"
            className=""
            name="name"
            value={badges[selected]?.name}
            onChange={handleInput}
          />
        </div>
        <div className="flex flex-col">
          <label>{t('date')}</label>
          <input
            type="date"
            className=""
            name="date"
            value={badges[selected]?.date}
            onChange={handleInput}
          />
        </div>
        <div className="flex flex-col">
          <label>{t('number')}</label>
          <input
            type="text"
            className=""
            name="number"
            value={badges[selected]?.number}
            onChange={handleInput}
          />
        </div>
        <div className="flex flex-col">
          <label>{t('address')}</label>
          <input
            type="text"
            className=""
            name="address"
            value={badges[selected]?.address}
            onChange={handleInput}
          />
        </div>
        <div className="flex flex-col">
          <label>{t('phone')}</label>
          <input
            type="text"
            className=""
            name="phone"
            value={badges[selected]?.phone}
            onChange={handleInput}
          />
        </div>
        <div className="flex gap-2">
          <label>{t('toilet')}</label>
          <input
            type="checkbox"
            name="toilet"
            onChange={handleCheckboxes}
            checked={badges[selected].permissions.toilet === 1}
            className="w-50 h-50"
          />
        </div>
        <div className="flex gap-2">
          <label>{t('leaving school')}</label>
          <input
            type="checkbox"
            name="canGoOut"
            onChange={handleCheckboxes}
            checked={badges[selected].permissions.canGoOut === 1}
            className="w-50 h-50"
          />
        </div>
        <div className="flex gap-2">
          <label>{t('restaurant')}</label>
          <input
            type="checkbox"
            name="restaurant"
            onChange={handleCheckboxes}
            checked={badges[selected].permissions.restaurant === 1}
            className="w-50 h-50"
          />
        </div>
      </div>
      <div className="print-source">
        <div
          ref={componentRef}
          className="mx-auto max-w-7xl flex items-center justify-center p-5 text-black"
          style={{
            height: '100%',
            width: '100%',
          }}
        >
          <div className="grid grid-cols-12">
            {badges.length % 2 != 0 ? (
              <div
                className={`col-span-6 `}
                style={{ order: badges.length - 1 }}
              ></div>
            ) : (
              ''
            )}
            {badges.map((badge, index) => (
              <div
                key={badge.id}
                style={{
                  order: index == badges.length - 1 ? index + 1 : index,
                }}
                className={`col-span-6`}
              >
                <BadgeCmp badge={badge} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <ReactToPrint
        trigger={() => (
          <button
            type="button"
            className="bg-blue-500 px-4 py-2 rounded text-white font-bold m-2"
          >
            {t('print')}
          </button>
        )}
        content={() => componentRef.current}
      />
    </div>
  );
}
