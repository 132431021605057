import React, { useEffect, useState } from 'react';
import { db } from '../../db';
import Swal from 'sweetalert2';
import LoadingCmp from '../LoadingCmp';
import Modal from '../../components/Modal';
import { useTranslation } from 'react-i18next';

function EditTeacherCmp(props) {
  const { t } = useTranslation()
  const [allcheckbox, setAllcheckbox] = useState({});
  const handleCheckbox = (e) => {
    e.persist();
    setAllcheckbox({ ...allcheckbox, [e.target.name]: e.target.checked });
  };

  const [inputs, setItem] = useState({
    name: '',
    subject: '',
    email: '',
    tel: '',
    status: '',
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.toedit) {
      setItem(props.toedit);
      setAllcheckbox({ status: props.toedit.status });
      setLoading(false);
    }
  }, [props.toedit]);

  const [errorsList, setErrors] = useState([]);

  const handleInput = (e) => {
    e.persist();
    setItem({ ...inputs, [e.target.name]: e.target.value });
  };

  const updateItem = async (e) => {
    e.preventDefault();
    setErrors({});
    const data = {
      name: inputs.name.trim(),
      subject: inputs.subject,
      email: inputs.email,
      tel: inputs.tel,
      status: allcheckbox.status ? 1 : 0,
    };
    if (!data.name)
      setErrors((errors) => ({
        ...errors,
        name: t("name required"),
      }));
    if (data.tel && data.tel.toString().length != 8)
      setErrors((errors) => ({
        ...errors,
        tel: 'Tel doit étre composée de 8 numéros ou vide',
      }));
    if (data.name && (data.tel.toString().length == 8 || !data.tel))
      try {
        await db.teachers.update(props.toedit.id, data);
        setErrors({});
        Swal.fire(t("success"), t("teacher updated successfully"), 'success');
      } catch (error) {
        setErrors((errors) => ({ ...errors, name: 'Nom existe déjà' }));
      }
  };
  if (loading) {
    return <LoadingCmp />;
  }
  return (
    <Modal
      show={props.show}
      hide={props.hide}
      dialogClassName="w-full sm:max-w-2xl h-fit my-auto pb-5 rounded-xl"
      afterLeave={props.afterLeave}
    >
      <div className="border-b mb-5 p-4 rounded-t-xl bg-gray-100 font-bold text-xl">
        Modifier Prof
      </div>
      <form onSubmit={updateItem} className="flex flex-col gap-4 px-6">
        <div className="flex flex-col">
          <label>{t('name')}</label>
          <input
            type="text"
            name="name"
            onChange={handleInput}
            value={inputs.name}
            className="p-2 border outline-none ring-gray-200 rounded focus:ring focus:ring-blue-400 transition duration-200"
          />
          <small className="text-red-500">{errorsList.name}</small>
        </div>
        <div className="flex flex-col">
          <label>{t('subject')}</label>
          <input
            type="text"
            name="subject"
            onChange={handleInput}
            value={inputs.subject}
            className="p-2 border outline-none ring-gray-200 rounded focus:ring focus:ring-blue-400 transition duration-200"
          />
          <small className="text-red-500">{errorsList.subject}</small>
        </div>
        <div className="flex flex-col">
          <label>{t('email')}</label>
          <input
            type="email"
            name="email"
            onChange={handleInput}
            value={inputs.email}
            className="p-2 border outline-none ring-gray-200 rounded focus:ring focus:ring-blue-400 transition duration-200"
          />
          <small className="text-red-500">{errorsList.email}</small>
        </div>
        <div className="flex flex-col">
          <label>{t('email')}</label>
          <input
            type="text"
            name="tel"
            onChange={handleInput}
            value={inputs.tel}
            className="p-2 border outline-none ring-gray-200 rounded focus:ring focus:ring-blue-400 transition duration-200"
          />
          <small className="text-red-500">{errorsList.tel}</small>
        </div>
        <div className="flex gap-2">
          <label>{t("available")}</label>
          <input
            type="checkbox"
            name="status"
            onChange={handleCheckbox}
            defaultChecked={allcheckbox.status === 1}
            className=""
          />
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-blue-500 text-white rounded-full w-[50%] p-2 font-bold"
          >
            {t("submit")}
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default EditTeacherCmp;
