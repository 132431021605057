import { IonIcon } from '@ionic/react';
import { create, trash } from 'ionicons/icons';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { db } from '../../../db';
import Swal from 'sweetalert2';

export default function TdClassroom(props) {
  const {t} = useTranslation();
  const [subject, setSubject] = useState([]);
  useEffect(() => {
    if (props?.item?.subject_id) {
      db.subjects.get(Number.parseInt(props.item.subject_id)).then((res) => {
        if (res) {
          setSubject(res);
        }
      });
    }
  }, [props?.item?.subject_id]);

  const [teacher, setTeacher] = useState([]);
  useEffect(() => {
    if (props?.item?.teacher_id) {
      db.teachers.get(Number.parseInt(props.item.teacher_id)).then((res) => {
        if (res) {
          setTeacher(res);
        }
      });
    }
  }, [props?.item?.teacher_id]);

  const [classe, setClasse] = useState([]);
  useEffect(() => {
    if (props?.item?.class_id) {
      db.classes.get(Number.parseInt(props.item.class_id)).then((res) => {
        if (res) {
          setClasse(res);
        }
      });
    }
  }, [props?.item?.class_id]);

  const deleteItem = (e, item) => {
    e.preventDefault();

    Swal.fire({
      title: 'Supprimer la matiere',
      text: `Etes-vous sûr que vous voulez supprimer la séance de ${subject.name} du ${item.start_time} à ${item.end_time}  ?`,
      showDenyButton: true,
      confirmButtonText: 'Supprimer',
      denyButtonText: `Annuler`,
      confirmButtonColor: '#df4759',
      denyButtonColor: '#d9e2ef',
    }).then((result) => {
      if (result.isConfirmed) {
        db.sessions.delete(item.id).then(() => {
          Swal.fire(t("success"), 'Séance supprimée avec succès', 'success');
        });
      } else if (result.isDenied) {
      }
    });
  };
  return (
    <>
      {props?.item?.classroom_id ? (
        <td className="border px-3 py-2 min-w-[80px]">
          <div className="grid grid-cols-12">
            <div className="col-span-4">
              <button
                type="button"
                className=""
                onClick={() => {
                  props.settoedit(props.item);
                  props.settoeditmodal(true);
                }}
              >
                <IonIcon
                  className="text-xl text-blue-500 justify-start"
                  icon={create}
                />
              </button>
            </div>
            <div className="col-span-4" />{' '}
            <div className="col-span-4">
              {' '}
              <button
                type="button"
                className=""
                onClick={(e) => deleteItem(e, props.item)}
              >
                <IonIcon
                  className="text-xl text-red-500 justify-start"
                  icon={trash}
                />
              </button>{' '}
            </div>{' '}
          </div>
          <div className="">{classe.name ? classe.name : ''}</div>
          <div className="">{teacher.name ? teacher.name : ''}</div>
          <div className="">{subject.name ? subject.name : ''}</div>
        </td>
      ) : (
        <td className="border px-3 py-2 min-w-[80px]">
          <button
            type="button"
            className="bg-blue-500 text-white font-bold rounded px-3 py-1"
            onClick={() => {
              props.settoadd(props.data);
              props.settoaddmodal(true);
            }}
          >
            +
          </button>
        </td>
      )}
    </>
  );
}
