import { useLiveQuery } from 'dexie-react-hooks';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../../db';
import { IonIcon } from '@ionic/react';
import { home } from 'ionicons/icons';
import LoadingCmp from '../../LoadingCmp';
import { useTranslation } from 'react-i18next';

export default function SchedulesByClass() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [itemsList, setItemsList] = useState([]);
  const classes = useLiveQuery(() => db.classes.toArray());
  useEffect(() => {
    if (classes) {
      setItemsList(classes);
      setLoading(false);
    }
  }, [classes]);

  if (loading) {
    return <LoadingCmp />;
  }
  let itemsHtml = '';
  if (itemsList.length > 0) {
    itemsHtml = (
      <>
        <div className="shadow-xl">
          <div className="items-center justify-between flex p-3 bg-gray-100 font-semibold">
            <h5 className="mb-3">{t('classes')} ({itemsList.length})</h5>
          </div>
          <div className="px-4">
            <div className="grid grid-cols-12">
              <div className="py-2 col-span-3">{t('name')}</div>
            </div>
            {itemsList.map((item, idx) => {
              return (
                <div className="p-2 mx-0 border-b grid grid-cols-12" key={idx}>
                  <div className="py-3 col-span-12">
                    <Link
                      className=""
                      to={`/schedules/schedules-by-class/${item.id}`}
                    >
                      <h5 className="text-black m-0">
                        <strong>{item.name}</strong>
                      </h5>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  } else {
    itemsHtml = (
      <div className="shadow-xl">
        <div className="items-center justify-between flex p-3 bg-gray-100 font-semibold">
          <h5 className="mb-3">{t('classes')} ({itemsList.length})</h5>
        </div>
        <div className="py-32 text-center font-semibold">
          <h4>{t("no classes yet")}</h4>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="text-gray-500 flex items-center gap-2">
        <Link className="flex items-center gap-2" to="/home">
          <IonIcon className="text-xl" icon={home} /> {t('home')}
        </Link>{' '}
        {'>>'} <Link to="/schedules">{t('schedules')}</Link> {' >>'} {t('classes schedules')}
      </div>
      <div className="max-w-5xl mx-auto p-0 sm:p-1 md:p-2 lg:p-4 xl:p-5">
        {itemsHtml}
      </div>
    </div>
  );
}
