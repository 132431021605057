import { IonIcon } from '@ionic/react';
import { home, layers, people } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Schedules() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="text-gray-500 flex items-center gap-2">
        <Link className="flex items-center gap-2" to="/home">
          <IonIcon className="text-xl" icon={home} /> {t('home')}
        </Link>{' '}
        {'>>'} {t('schedules')}
      </div>
      <div className="grid grid-cols-12 justify-center">
        <div className="col-span-12 sm:col-span-10 md:col-span-6 lg:col-span-6 xl:col-span-4">
          <Link className="text-dark" to="schedules-by-class">
            <div className="shadow gap-4 flex items-center justify-center m-5 p-4">
              <IonIcon className="text-4xl flex-start" icon={layers} />
              <div className="text-center text-xl font-semibold">
                {t('classes schedules')}
              </div>
            </div>
          </Link>
        </div>
        <div className="col-span-12 sm:col-span-10 md:col-span-6 lg:col-span-6 xl:col-span-4">
          <Link className="text-dark" to="schedules-by-teacher">
            <div className="shadow gap-4 flex items-center justify-center m-5 p-4">
              <IonIcon className="text-4xl flex-start" icon={people} />
              <div className="font-semibold text-xl text-center">
              {t('teachers schedules')}
              </div>
            </div>
          </Link>
        </div>
        <div className="col-span-12 sm:col-span-10 md:col-span-6 lg:col-span-6 xl:col-span-4">
          <Link className="text-dark" to="schedules-by-classroom">
            <div className="shadow gap-4 flex items-center justify-center m-5 p-4">
              <IonIcon className="text-4xl flex-start" icon={layers} />
              <div className="text-xl text-center font-semibold">
              {t('classrooms schedules')}
              </div>
            </div>
          </Link>
        </div>
        <div className="col-span-12 sm:col-span-10 md:col-span-6 lg:col-span-6 xl:col-span-4">
          <Link className="text-dark" to="summary">
            <div className="shadow gap-4 flex items-center justify-center m-5 p-4">
              <IonIcon className="text-4xl flex-start" icon={layers} />
              <div className="text-xl text-center font-semibold">
              {t('summary schedules')}
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
