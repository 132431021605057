import i18n from 'i18next';
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from 'react-i18next';
import enTranslation from './assets/i18n/en.json';
import arTranslation from './assets/i18n/ar.json';

i18n
    .use(i18nBackend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'ar',
        lng: 'ar',
        interpolation: {
            escapeValue: false
        },
        resources: {
          en: {
            translation: enTranslation
          },
          ar: {
            translation: arTranslation
          }

        },
    });

export default i18n;
