import { useLiveQuery } from 'dexie-react-hooks';
import React, { useEffect, useState } from 'react';
import { db } from '../../db';
import Swal from 'sweetalert2';
import Modal from '../../components/Modal';
import LoadingCmp from '../LoadingCmp';
import { useTranslation } from 'react-i18next';

export default function EditClassCmp(props) {
  const { t } = useTranslation();
  const [classesList, setClasses] = useState([]);
  const classes = useLiveQuery(() =>
    db.classes.where({ class_1_id: '' }).toArray()
  );

  useEffect(() => {
    if (classes) {
      setClasses(classes);
    }
  }, [classes]);

  const [inputs, setItem] = useState({
    name: '',
    class_1_id: '',
    class_2_id: '',
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.toedit) {
      setItem(props.toedit);
      setLoading(false);
    }
  }, [props.toedit]);

  const [errorsList, setErrors] = useState([]);

  const handleInput = (e) => {
    e.persist();
    setItem({ ...inputs, [e.target.name]: e.target.value });
  };

  const updateItem = async (e) => {
    e.preventDefault();
    setErrors({});
    const data = {
      name: inputs.name.trim(),
      class_1_id: inputs.class_1_id ? Number.parseInt(inputs.class_1_id) : '',
      class_2_id: inputs.class_2_id ? Number.parseInt(inputs.class_2_id) : '',
    };
    if (!data.class_1_id ^ !data.class_2_id)
      setErrors((errors) => ({
        ...errors,
        class_2_id: 'On peut pas selectionner une seule classe',
        class_1_id: 'On peut pas selectionner une seule classe',
      }));
    if (data.name && !(!data.class_1_id ^ !data.class_2_id))
      try {
        await db.classes.update(props.toedit.id, data);
        setErrors({});
        Swal.fire(t("success"), t("class updated successfully"), 'success');
      } catch (error) {
        setErrors((errors) => ({ ...errors, name: 'Nom existe déjà' }));
      }
  };
  if (loading) {
    return <LoadingCmp />;
  }
  return (
    <Modal
      show={props.show}
      hide={props.hide}
      dialogClassName="w-full sm:max-w-2xl h-fit my-auto pb-5 rounded-xl"
      afterLeave={props.afterLeave}
    >
      <div className="border-b mb-5 p-4 rounded-t-xl bg-gray-100 font-bold text-xl">
        Modifier Classe
      </div>
      <form onSubmit={updateItem} className="flex flex-col gap-4 px-6">
        <div className="flex flex-col">
          <label>{t('name')}</label>
          <input
            type="text"
            name="name"
            onChange={handleInput}
            value={inputs.name}
            className="p-2 border outline-none ring-gray-200 rounded focus:ring focus:ring-blue-400 transition duration-200"
          />
          <small className="text-red-500">{errorsList.name}</small>
        </div>
        {inputs.class_1_id ? (
          <div className="">
            <label>{t("class 1")}</label>
            <div className="flex flex-col">
              <select
                name="class_1_id"
                onChange={handleInput}
                value={inputs.class_1_id}
                className="border p-2"
              >
                <option value="">{t("select class 1")}</option>
                {classesList.map((item) => {
                  return item.id != inputs.class_2_id ? (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ) : (
                    ''
                  );
                })}
              </select>
            </div>
            <small className="text-red-500">{errorsList.class_1_id}</small>
          </div>
        ) : (
          ''
        )}
        {inputs.class_1_id ? (
          <div className="">
            <label>{t("class 2")}</label>
            <div className="flex flex-col">
              <select
                name="class_2_id"
                onChange={handleInput}
                value={inputs.class_2_id}
                className="border p-2"
              >
                <option value="">{t("select class 2")}</option>
                {classesList.map((item) => {
                  return item.id != inputs.class_1_id ? (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ) : (
                    ''
                  );
                })}
              </select>
            </div>
            <small className="text-red-500">{errorsList.class_2_id}</small>
          </div>
        ) : (
          ''
        )}
        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-blue-500 text-white rounded-full w-[50%] p-2 font-bold"
          >
            {t("submit")}
          </button>
        </div>
      </form>
    </Modal>
  );
}
